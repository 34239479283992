import React from "react";
import {convertSecondToHours} from "../../utils/general-variable";
import CompletedSvg from "../../images/svg/Frame 165.svg";
import PausedSvg from "../../images/svg/Frame 166.svg";
import ProgressSvg from "../../images/svg/Frame 164.svg";
import {useSelector} from "react-redux";
import PaginationComponent from "../../components/pagination.component";

const TaskListMobileComponent = () => {
    const {totalData, taskList} = useSelector(state => state.emailReducer)
    const {bulkHoursRemaining} = totalData

    return (
            <div className={"col space-y-5"}>
                {
                    taskList.map((item, index) => (
                        <div className={"bg-white shadow-lg p-7 rounded-lg col space-y-5"} key={index}>
                            <p>{item.name}</p>
                            <div className={"grid grid-cols-2 gap-5"}>
                                <div className={"space-y-2"}>
                                    <p className={"text-[12px] text-dark opacity-60"}>Task Duration</p>
                                    <p className={"text-[14px] text-dark-purple font-medium"}>{convertSecondToHours(item.taskDuration, true)}</p>
                                </div>
                                <div className={"space-y-2"}>
                                    <p className={"text-[12px] text-dark opacity-60"}>Status</p>
                                    <img
                                        src={!!item?.completionDate ? CompletedSvg : bulkHoursRemaining === 0 ? PausedSvg : ProgressSvg}/>
                                </div>
                            </div>
                        </div>
                    ))
                }
                <PaginationComponent slug={'christmas'} />
            </div>
    )
}

export default TaskListMobileComponent
