import React from "react";
import Rectangle from "../../images/svg/Rectangle 13.svg";
import {Popover} from "@headlessui/react";

const AdminPopupComponent = () => {
    const menuList = [
        {
            label: 'Dashboard',
            path: '/'
        }, {
            label: 'Admin Setting',
            path: '/admin'
        }, {
            label: 'Logout',
            path: '/admin'
        }
    ]
    return (
        <Popover className="relative">
            <Popover.Button><img src={Rectangle}/></Popover.Button>
            <Popover.Panel className="absolute z-10 bg-white shadow-xl rounded-md top-10 right-0 divide-y text-dark">
                {
                    menuList.map((item, index) => (
                        <div className={"w-full py-3 w-32"} key={index}>
                            <div className={"row items-center pl-5 font-Poppins-SemiBold"}>
                                <a href={item.path} className={"hover:text-gray-400"}>{item.label}</a>
                            </div>
                        </div>
                    ))
                }
            </Popover.Panel>
        </Popover>
    )
}

export default AdminPopupComponent
