import {combineReducers, configureStore} from "@reduxjs/toolkit";
import toolsReducer from "./tools.reducer";
import emailReducer from "./email.reducer";
import adminReducer from "./admin.reducer";
import projectReducer from "./projects.reducer";

const rootReducer = combineReducers({
    toolsReducer,
    emailReducer,
    adminReducer,
    projectReducer
})

const store = configureStore({reducer: rootReducer})

export default store
