import React from "react";

const ModalComponent = (props) => {
    const {content, visibility, onClose} = props

    return (
        <div
            className={` ${visibility ? 'block' : 'hidden'} fixed flex left-0 top-0 w-full h-full justify-center items-center block z-10`}>
            <div onClick={onClose} className={"absolute bg-black/[.40] inset-0"}/>
            <div className={"absolute bg-white w-2/5 rounded-lg"}>
                {content}
            </div>
        </div>
    )
}

export default ModalComponent
