import {createSlice} from '@reduxjs/toolkit'

export const toolsSlice = createSlice({
    name: 'tools',
    initialState: {
        dateRange: [null, null],
        searchKey: '',
        sortBy: 'name',
        sortType: true,
        currentPage: 1,
        pageSize: 10,
        totalData: 100,
        showDatePicker: false,
        totalPage: 1,
        startDate: null,
        endDate: null,
    },
    reducers: {
        setToolsSearchKey: (state, action) => {
            state.searchKey = action.payload
        },
        setToolsSort: (state, action) => {
            state.sortBy = action.payload
        },
        setToolsSortType: (state, action) => {
            state.sortType = action.payload
        },
        setToolsCurrentPage: (state, action) => {
            state.currentPage = action.payload
        },
        setToolsPageSize: (state, action) => {
            state.pageSize = action.payload
        },
        setToolsStartDate: (state, action) => {
            state.startDate = action.payload
        },
        setToolsEndDate: (state, action) => {
            state.endDate = action.payload
        },
        setToolsShowDatePicker: (state, action) => {
            state.showDatePicker = action.payload
        },
        setToolsTotalData: (state, action) => {
            state.totalData = action.payload
        },
        setToolsTotalPage: (state, action) => {
            state.totalPage = action.payload
        },
        setToolsDateRange: (state, action) => {
            state.dateRange = action.payload
        },
    },
})

export const {
    setToolsSearchKey,
    setToolsSort,
    setToolsCurrentPage,
    setToolsStartDate,
    setToolsEndDate,
    setToolsShowDatePicker,
    setToolsSortType,
    setToolsPageSize,
    setToolsTotalPage,
    setToolsTotalData,
    setToolsDateRange
} = toolsSlice.actions
const toolsReducer = toolsSlice.reducer

export default toolsReducer
