import './App.css';
import {Provider} from "react-redux";
import store from "./reducers";
import {BrowserRouter} from "react-router-dom";
import Dashboard from "./pages/dashboard";
import RoutePage from "./routes";

function App() {
  return (
      <Provider store={store}>
          <BrowserRouter>
              <Dashboard content={<RoutePage/>}/>
          </BrowserRouter>
      </Provider>
  );
}

export default App;
