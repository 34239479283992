import React from "react";
import HourGlass from "../../images/Group 1374.png"
import GoldClock from "../../images/Group 1369.png"
import TodoListPng from "../../images/Group 1371.png";
import TodoListWhiteSvg from "../../images/svg/Group 194.svg";
import CheckList from "../../images/Group 192.png"
import PausedSvg from "../../images/svg/pause.svg";
import HoursRemainingBackground from "../../images/svg/Group 1375.png"
import {convertSecondToHours, defineHoursRemainingCardColor, formatDateToReadable} from "../../utils/general-variable";
import {useSelector} from "react-redux";

const TotalCardComponent = () => {
    const {totalData, activeButton} = useSelector(state => state.emailReducer)
    const {dateRange} = useSelector(state => state.toolsReducer)

    const textByActiveButton = () => {
        switch (activeButton) {
            case 'day':
            case 'week':
            case 'month':
                return `last ${activeButton}`
            case 'lifetime':
                return activeButton
            default:
                return `${formatDateToReadable(dateRange[0], 'DD/MM/YYYY')} to ${formatDateToReadable(dateRange[1], 'DD/MM/YYYY')}`
        }
    }
    const buttonList = [
        {
            key: 'hoursLogged',
            content: `Hours Logged ${textByActiveButton()}`,
            type: 'hours',
            image: GoldClock
        }, {
            key: 'totalCompleted',
            content: 'Tasks Completed',
            type: 'int',
            image: TodoListPng
        },
    ]

    return (
        <div className={"w-full col"}>
            <div className={"grid lg:grid-cols-4 grid-cols-2 lg:gap-5 4k:gap-10 gap-4 font-Poppins-Regular"}>
                {
                    totalData['bulkHoursRemaining'] < 1200 ?
                        <div className={"lg:py-5"}>
                            <div
                                className={`relative py-2 lg:py-5 2k:h-44 2xl:px-5 px-2 row items-center justify-center rounded-lg shadow-xl bg-white text-dark ${defineHoursRemainingCardColor(totalData['bulkHoursRemaining'])}`}>
                                <div className={"row lg:items-start items-center justify-between w-full"}>
                                    <div className={"col lg:space-y-5 2k:space-y-8 space-y-1"}>
                                        <p className={"font-Poppins-Medium 2xl:text-xs 2k:text-2xl lg:text-[11px] text-[9.5px]"}>Bulk Hours Remaining</p>
                                        <p className={"2xl:text-2xl 2k:text-4xl text-xl font-Poppins-SemiBold"}>{convertSecondToHours(totalData['bulkHoursRemaining'])}</p>
                                    </div>
                                    <img src={HourGlass} className={"2xl:w-8 w-7 2k:w-14 shadow-lg rounded-full"}/>
                                </div>
                            </div>
                        </div>
                        :
                        <div className={"relative lg:h-28 2k:h-44 4k:h-72 lg:py-5"}>
                            <img src={HoursRemainingBackground}
                                 className={"absolute bg-contain rounded-lg 4k:rounded-2xl h-full w-full"}/>
                            <div
                                className={`lg:py-5 2xl:px-5 py-2 px-2 2k:py-9 4k:py-14 4k:px-10 row items-center justify-center absolute w-full`}>
                                <div className={"row lg:items-start items-center justify-between w-full text-white"}>
                                    <div className={"col lg:space-y-5 2k:space-y-8 4k:space-y-16 space-y-1"}>
                                        <p className={"font-Poppins-Medium lg:text-xs 2k:text-2xl 4k:text-[40px] text-[9.5px]"}>Bulk Hours Remaining</p>
                                        <p className={"md:text-2xl 2k:text-4xl 4k:text-7xl text-xl font-Poppins-SemiBold"}>{convertSecondToHours(totalData['bulkHoursRemaining'])}</p>
                                    </div>
                                    <img src={HourGlass} className={"2xl:w-8 2k:w-14 4k:w-24 w-7 shadow-lg rounded-full"}/>
                                </div>
                            </div>
                        </div>
                }
                {
                    buttonList.map((item, index) => (
                        <div className={"lg:bg-square lg:py-5 bg-no-repeat bg-center"} key={index}>
                            <div
                                className={`relative py-2 lg:py-5 2k:py-9 4k:py-14 px-2 2xl:px-5 4k:px-10 row items-center justify-center rounded-lg 4k:rounded-2xl shadow-xl border bg-white text-dark`}>
                                <div className={"row lg:items-start items-center justify-between w-full"}>
                                    <div className={"col space-y-1 lg:space-y-5 2k:space-y-8 4k:space-y-16"}>
                                        <p className={"font-Poppins-Medium 2xl:text-xs lg:text-[11px] 2k:text-2xl 4k:text-[40px] text-[9.5px] capitalize"}>{item.content}</p>
                                        <p className={"text-xl 2xl:text-2xl 2k:text-4xl 4k:text-7xl font-Poppins-SemiBold"}>{item.type === 'int' ? totalData[item.key] : convertSecondToHours(totalData[item.key])}</p>
                                    </div>
                                    <img src={item.image} className={"2xl:w-8 2k:w-14 4k:w-24 w-7 shadow-lg rounded-full"}/>
                                </div>
                            </div>
                        </div>
                    ))
                }
                <div className={"lg:bg-square lg:py-5 bg-no-repeat bg-center"}>
                    <div
                        className={`relative px-2 lg:py-5 2k:py-9 2xl:px-5 4k:py-14 4k:px-10 py-2 rounded-lg 4k:rounded-2xl shadow-xl border ${totalData['hoursRemaining'] === 0 ? 'text-white bg-gradient-to-tr from-dark-orange to-light-orange border-b-2 border-b-dark bg-opacity-60' : ' bg-white text-dark'}`}>
                        <div className={"row lg:items-start items-center justify-between w-full"}>
                            <div className={"col lg:space-y-5 2k:space-y-8 4k:space-y-16 space-y-1"}>
                                <p className={"font-Poppins-Medium 2xl:text-xs 2k:text-2xl lg:text-[11px] 4k:text-[40px] text-[9.5px]"}>{totalData['hoursRemaining'] === 0 ? 'Task Paused' : 'Tasks In Progress'}</p>
                                <p className={"2xl:text-2xl 2k:text-4xl 4k:text-7xl text-xl font-Poppins-SemiBold"}>{totalData['totalInProgress']}</p>
                            </div>
                            <img src={totalData['hoursRemaining'] === 0 ? TodoListWhiteSvg : CheckList}
                                 className={"2xl:w-8 2k:w-14 4k:w-24 w-7 shadow-lg rounded-full"}/>
                        </div>
                        {
                            totalData?.hoursRemaining === 0 &&
                            <div
                                className={"absolute top-0 left-0 bg-orange bg-opacity-30 md:h-[80px] h-[65px] w-full md:h-[120px] col items-center justify-center rounded-lg"}>
                                <img src={PausedSvg} className={"w-5 mt-2"}/>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TotalCardComponent
