import moment from "moment";

const authToken = localStorage.getItem('TIME_TO_MONDAY_TOKEN');

const convertMinutesToHours = (minutes, withColon) => {

    const numberWithZero = (n) => {
        let num = n < 10 ? `0${n}` : n
        return num
    }
    let h = minutes / 60
    let m = minutes % 60
    let hour = Math.trunc(h)

    let convertedMinutes = withColon ? `${numberWithZero(hour)}h : ${numberWithZero(m)}m` : `${numberWithZero(hour)}h ${numberWithZero(m)}m`

    return convertedMinutes
}

const convertSecondToHours = (second, withColon) => {

    const numberWithZero = (n) => {
        let num = n < 10 ? `0${n}` : n
        return num
    }
    let h = second / 3600
    let minMod = second % 3600
    let m = minMod / 60
    let hour = Math.trunc(h)
    let minute = Math.trunc(m)

    let convertedMinutes = withColon ? `${numberWithZero(hour)}h : ${numberWithZero(minute)}m` : `${numberWithZero(hour)}h ${numberWithZero(minute)}m`

    return convertedMinutes
}

const defineHoursRemainingCardColor = (hours) => {
    if (hours === 0) {
        return 'border-4 border-maroon text-maroon'
    } else if (hours < 300) {
        return 'border-4 border-red-two'
    } else if (hours < 600) {
        return 'border-4 border-orange-two'
    } else if (hours < 1200) {
        return 'border'
    }
}

const convertText = (text) => {
    switch (text) {
        case 'progress':
            return 'in progress'
        default:
            return text
    }
}

const formatDateToReadable = (date, format) => {
    return moment.unix(date).format(!!format ? format : "DD-MM-YYYY")
}

const convertDateToCustomFormat = (date, format) => {
    return moment(date).format(format)
};

const formatDateStartOfUnix = (date) => {
    return moment(date).startOf('day').unix()
}

const formatDateEndOfUnix = (date) => {
    return moment(date).endOf('day').unix()
}

const convertUnixToMoment = (date) => {
    return moment.unix(date)
}

export {
    authToken,
    convertMinutesToHours,
    defineHoursRemainingCardColor,
    convertText,
    formatDateToReadable,
    convertDateToCustomFormat,
    formatDateStartOfUnix,
    formatDateEndOfUnix,
    convertUnixToMoment,
    convertSecondToHours
}
