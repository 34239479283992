import React, {useEffect, useState} from "react";
import ArcticGreyLogo from "../../images/svg/Group 1195.svg"
import ChevronRight from "../../images/svg/bx-chevron-down (2) 3.svg"
import ChevronDown from "../../images/svg/bx-chevron-down (2) 1.svg"
import pluscircle from "../../images/svg/bx-plus-circle (1) 1.svg"
import {Link, useLocation} from "react-router-dom";
import {setProjectAddProjectModal} from "../../reducers/projects.reducer";
import {useDispatch} from "react-redux";

const SidebarComponent = () => {
    const location = useLocation();
    const dispatch = useDispatch()
    const pathRaw = location.pathname.split('/')
    const [activeTab, setActiveTab] = useState(null)
    const sidebarList = [
        {
            name: 'Arctic Grey, Ltd.',
            id: 1,
            time: '51h 33m'
        }, {
            name: 'Barnes & Nobels',
            id: 2,
            time: '48h 33m'
        }, {
            name: 'Havsies',
            id: 3,
            time: '151h 21m',
        }, {
            name: 'Josh Rosebrook',
            id: 4,
            time: '51h 32m',
        }, {
            name: 'Kleem Organics',
            id: 5,
            time: '51h 32m',
        }, {
            name: 'MiniBrew',
            id: 6,
            time: '51h 32m',
        }, {
            name: 'OLIPHANT',
            id: 7,
            time: '51h 32m',
        }, {
            name: 'Ross Metals',
            id: 8,
            time: '51h 32m',
        }, {
            name: 'Sarah Chloe',
            id: 9,
            time: '51h 32m',
        }, {
            name: 'Armacost Lighting',
            id: 10,
            time: '51h 32m',
        }, {
            name: 'Freewheel Bike',
            id: 11,
            time: '51h 32m',
        }, {
            name: 'The Cup Store',
            id: 12,
            time: '51h 32m',
        }, {
            name: 'TooTimid',
            id: 13,
            time: '51h 32m',
        }, {
            name: 'AiSpeed - 3.0',
            id: 14,
            time: '51h 32m',
        },
    ]

    useEffect(() => {
        setActiveTab(pathRaw[1] === '' ? 'all-project' : pathRaw[2])
    }, [])

    const sidebarHandler = (key) => {
        setActiveTab(key)
    }

    const addNewProjectHandler = () => {
        dispatch(setProjectAddProjectModal(true))
    }

    return (
        <div className={"bg-dark-navy text-white justify-between h-full col fixed w-[240px]"}>
            <div className={"h-5/6"}>
                <div className={"col items-center py-5 bg-dark-navy"}>
                    <img src={ArcticGreyLogo}/>
                </div>
                <div className={"w-full border-b opacity-20"}/>
                <div className={"w-full pl-2.5 pt-4 font-Poppins-Bold"}>
                    <Link
                        to={'/'}
                        onClick={() => sidebarHandler('all-project')}
                        className={`row px-4 uppercase hover:text-dark hover:bg-white-smoke hover:rounded-l-lg justify-between w-full text-xs py-3 cursor-pointer ${'all-project' === activeTab ? 'bg-white-smoke text-dark rounded-l-lg font-bold hover:text-dark' : ''}`}>
                        all projects
                        <img src={'all-project' === activeTab ? ChevronRight : ChevronDown}/>
                    </Link>
                    <div
                        className={"row px-4 uppercase justify-between w-full text-xs py-3 rounded-tr-lg bg-dark-navy"}>
                        recently active
                        <img src={ChevronDown}/>
                    </div>
                    <div className={"font-Poppins-Regular mt-7 col overflow-y-scroll h-[500px]"}>
                        {
                            sidebarList.map((item, index) => (
                                <Link
                                    to={`/project-detail/${item.id}`}
                                    onClick={() => sidebarHandler(String(item.id))}
                                    className={`row justify-between px-4 py-3 cursor-pointer hover:text-dark hover:bg-white-smoke hover:rounded-l-lg ${String(item.id) === activeTab ? 'bg-white-smoke text-dark rounded-l-lg font-bold hover:text-dark' : ''}`}
                                    key={index}>
                                    <p>{item.name}</p>
                                    <p className={"text-10 opacity-60"}>{item.time}</p>
                                </Link>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className={"bg-black font-Poppins-SemiBold px-4 text-sm py-3 cursor-pointer"}
                 onClick={addNewProjectHandler}>
                <div className={"row pl-2.5 pt-4 w-full justify-between"}>
                    Add a New Project
                    <img src={pluscircle} className={"w-5"}/>
                </div>
            </div>
        </div>
    )
}

export default SidebarComponent
