import React from "react";
import {message} from "antd";
import LinkSvg from "../../images/svg/Frame.svg";
import {useDispatch, useSelector} from "react-redux";
import {setEmailActiveButton, setEmailTotalData} from "../../reducers/email.reducer";
import DatepickerComponent from "../../components/datepicker.component";
import {
    convertSecondToHours,
    formatDateEndOfUnix,
    formatDateStartOfUnix,
    formatDateToReadable
} from "../../utils/general-variable";
import {setToolsDateRange, setToolsShowDatePicker} from "../../reducers/tools.reducer";
import moment from "moment";
import {useParams} from "react-router-dom";

const FilterDateComponent = () => {
    const dispatch = useDispatch()
    const {id, start, end} = useParams();
    const {showDatePicker, dateRange} = useSelector(state => state.toolsReducer)
    const {projectTitle, totalData, activeButton} = useSelector(state => state.emailReducer)
    const {hoursLogged} = totalData
    const textUrl = `https://arcticgrey.monday.com/boards/${id}`
    const buttonList = [
        {
            label: 'Day',
            value: 0,
            key: 'day',
            startDate: formatDateStartOfUnix(),
            endDate: formatDateEndOfUnix()
        }, {
            label: 'Week',
            value: 100,
            key: 'week',
            startDate: start,
            endDate: end
        }, {
            label: 'Month',
            value: 300,
            key: 'month',
            startDate: moment().startOf('month').unix(),
            endDate: formatDateEndOfUnix()
        }, {
            label: 'Lifetime',
            value: 600,
            key: 'lifetime',
            startDate: moment().startOf('year').unix(),
            endDate: formatDateEndOfUnix()
        }
    ]

    const copyToClipboard = () => {
        navigator.clipboard.writeText(textUrl)
        message.success('Copied!')
    }

    const changeActiveButton = (item) => {
        dispatch(setToolsShowDatePicker(false))
        dispatch(setToolsDateRange([item.startDate, item.endDate]))
        dispatch(setEmailTotalData(item.value))
        dispatch(setEmailActiveButton(item.key))
    }

    return (
        <div className={"w-full col md:row items-start justify-between mb-7 lg:mb-0"}>
            <div className={"lg:space-y-1 2k:space-y-4 4k:space-y-10 space-y-4 w-full md:w-auto"}>
                <p className={"text-base 2k:text-3xl 4k:text-5xl lg:font-Poppins-Bold text-dark"}>{projectTitle}</p>
                <div className={"row items-center space-x-1 md:hidden block"}>
                    <div className={"row items-center space-x-1"}>
                        <p className={"text-base font-Poppins-Bold text-dark"}>{convertSecondToHours(hoursLogged)}</p>
                        <p className={"text-base text-dark"}>Logged</p>
                    </div>
                </div>
                <div className={"row items-center 2k:text-2xl 4k:text-4xl space-x-3"}>
                    <p className={"text-dark hidden md:block flex-wrap"}>Live Project Board :</p>
                    <p className={"lg:hidden block flex-wrap"}>Public Link</p>
                    <div
                        className={"row items-center space-x-2 4k:space-x-5 flex-1 md:justify-start md:flex-wrap w-4/5"}>
                        <a className={"text-10 2k:text-2xl 4k:text-4xl text-light-grey border-b border-light-grey truncate"}
                           target={'_blank'}
                           href={textUrl}>{textUrl}</a>
                        <img src={LinkSvg} className={"cursor-pointer 2k:w-8 4k:w-12"} onClick={copyToClipboard}/>
                    </div>
                </div>
            </div>
            <div
                className={"lg:row col lg:items-center md:mt-0 space-y-2 lg:space-y-0 lg:space-x-2 4k:space-x-10 md:space-y-2 mt-5 md:w-auto w-full"}>
                <p className={"2xl:text-xs lg:text-[11px] 2k:text-2xl 4k:text-4xl"}>{formatDateToReadable(dateRange[0], 'MMM DD, YYYY')} - {formatDateToReadable(dateRange[1], 'MMM DD, YYYY')}</p>
                <div className={"row items-center space-x-2 2k:space-x-4 4k:space-x-8 "}>
                    {
                        buttonList.map((item, index) => (
                            <button
                                onClick={() => changeActiveButton(item)}
                                className={`${!showDatePicker && (activeButton === item.key) ? 'bg-white shadow-sm' : 'bg-dark bg-opacity-10'} text-dark 2xl:text-10 lg:text-[9px] 2k:text-2xl font-medium h-6 2k:h-12 2k:h-16 px-2.5 2k:px-3 rounded 4k:text-4xl 4k:px-10 4k:rounded-xl`}
                                key={index}>{item.label}
                            </button>
                        ))
                    }
                    <DatepickerComponent activeButton={activeButton}
                                         onChangeActiveButton={(a) => dispatch(setEmailActiveButton(a))}/>
                </div>
            </div>
        </div>
    )
}

export default FilterDateComponent
