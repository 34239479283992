import React, {useEffect, useState} from "react";
import Search from "../../images/svg/search.svg"
import NotificationComponent from "./notification.component";
import AdminPopupComponent from "./admin-popup.component";
import {useDispatch, useSelector} from "react-redux";
import {setToolsSearchKey} from "../../reducers/tools.reducer";

const HeaderComponent = () => {
    const dispatch = useDispatch()
    const [search, setSearch] = useState('')
    const {searchKey} = useSelector(state => state.toolsReducer)

    useEffect(() => {
        if (searchKey.length > 0) {
            setSearch(searchKey)
        }
    }, [searchKey])

    const onSearchHandler = (value) => {
        setSearch(value)
    }

    const inputHandler = (key) => {
        if (key === "Enter") {
            dispatch(setToolsSearchKey(search))
        }
    }

    return (
        <div className={"bg-white h-14 shadow-md sticky top-0 row justify-between items-center px-7 z-10"}>
            <div className={"row space-x-4 w-full"}>
                <img src={Search} className={"w-4"}/>
                <input
                    onChange={(e) => onSearchHandler(e.target.value)}
                    onKeyDown={(e) => inputHandler(e.key)}
                    className={"text-dark outline-none text-sm w-2/5"} placeholder={'Search . . .'}/>
            </div>
            <div className={"row items-center space-x-4"}>
                <NotificationComponent/>
                <AdminPopupComponent/>
            </div>
        </div>
    )
}

export default HeaderComponent
