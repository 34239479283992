import React from "react";
import GroupExit from "../../images/svg/Group exit.svg"
import AddSvg from "../../images/svg/GroupPlus.svg"

const AddProjectModalComponent = () => {
    return (
        <div className={"border pb-5 rounded-lg"}>
            <p className={"text-xl text-dark-purple font-Montserrat-SemiBold row justify-between px-8 py-8"}>Add New
                Project
                <img src={GroupExit}/>
            </p>
            <div className={"w-full bg-gradient-to-r from-blue to-sky-blue h-0.5"}/>
            <div className={"row px-14 py-1 mt-10 space-x-8 items-center"}>
                <p className={"font-Poppins-SemiBold text-xs w-36"}>Project Board ID</p>
                <div className={"row items-center justify-between border px-3 rounded-md w-80 shadow-md"}>
                    <input className={"h-10 w-full outline-none"}/>
                    <img src={AddSvg} className={"w-5"}/>
                </div>
            </div>
            <div className={"row px-14 py-2 space-x-8 items-center"}>
                <p className={"font-Poppins-SemiBold text-xs w-36"}>Add Bulk Hours</p>
                <input className={"border p-3 rounded-md w-80 shadow-md outline-none"}/>
            </div>
            <div className={"row px-14 pt-2 space-x-8 items-center"}>
                <p className={"font-Poppins-SemiBold text-xs w-36"}>Email Notifications</p>
                <input className={"border p-3 rounded-md w-80 shadow-md outline-none"} placeholder={"Email Address 1"}/>
            </div>
            <div className={"row px-14 py-2 space-x-8"}>
                <div className={"w-36"}/>
                <div className={"col w-80 space-y-2 "}>
                    <input className={"border p-3 rounded-md shadow-sm outline-none shadow-md"} placeholder={"Email Address 2"}/>
                    <input className={"border p-3 rounded-md shadow-sm outline-none shadow-md"} placeholder={"Email Address 3"}/>
                    <input className={"border p-3 rounded-md shadow-sm outline-none shadow-md"} placeholder={"Email Address 4"}/>
                    <input className={"border p-3 rounded-md shadow-sm outline-none shadow-md"} placeholder={"Email Address 5"}/>
                    <div className={"row justify-end"}>
                        <button
                            className={"py-3 px-7 items-end bg-dark-purple font-Montserrat-SemiBold text-xs text-white rounded-md mt-5"}>Add
                            Project
                        </button>
                    </div>
                </div>

            </div>
        </div>

    )
}

export default AddProjectModalComponent
