import axios from "axios";
import {baseUrl} from "./api.config";

const getTaskByBoardIdApi = (id, params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${baseUrl()}/v1/admin/project/tasks/${id}`, {params})
            .then(response => resolve(response.data.data))
            .catch(err => reject(err.response))
    })
}

const getTitleByBoardIdApi = (id, params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${baseUrl()}/v1/admin/project/${id}`, {params})
            .then(response => resolve(response.data.data))
            .catch(err => reject(err.response))
    })
}

export {
    getTaskByBoardIdApi,
    getTitleByBoardIdApi
}

