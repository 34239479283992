import HourGlassIcon from "../images/Group 1374.png";
import TodoListIcon from "../images/Group 1371.png";
import GoldClockIcon from "../images/Group 1369.png";
import StatusIcon from "../images/svg/Frame 144.svg"
import SyncDataIcon from "../images/svg/Frame 144 (1).svg"
import GroupExitIcon from "../images/svg/Group exit.svg"
import AddSvgIcon from "../images/svg/GroupPlus.svg"
import SortIcon from "../images/svg/sort.svg"
import ArrowRightIcon from "../images/svg/Group 195.svg"
import ArrowLeftIcon from "../images/svg/Group 196.svg"
import LoveIcon from "../images/svg/love.svg"
import CoffeeIcon from "../images/svg/coffee.svg"
import SearchIcon from "../images/svg/search.svg"
import ChevronIcon from '../images/svg/arrow-down.svg'
import HoursRemainingBackground from "../images/svg/Group 1375.png";
import TodoListWhiteIcon from "../images/svg/Group 194.svg";
import CheckListIcon from "../images/Group 192.png";
import PausedIcon from "../images/svg/pause.svg";
import UpToDateIcon from "../images/svg/Frame 144.svg";
import RectangleIcon from "../images/svg/Rectangle 13.svg";
import ArcticGreySmallIcon from "../images/svg/Group 271.svg";
import SandwichIcon from "../images/svg/Frame (3).svg"
import SearchBoxIcon from "../images/svg/bx-search (2) 1.svg"
import ArcticGreyLogoIcon from "../images/svg/Group 1195.svg"
import Frame5Icon from "../images/svg/Frame (5).svg"
import Frame6Icon from "../images/svg/Frame (6).svg"
import PlusCircleIcon from "../images/svg/bx-plus-circle (1) 1.svg";
import Frame1Icon from "../images/svg/Frame (1).svg";
import Frame4Icon from "../images/svg/Frame (4).svg"
import ChevronRightIcon from "../images/svg/bx-chevron-down (2) 3.svg"
import ChevronDownIcon from "../images/svg/bx-chevron-down (2) 1.svg"
import ArcticLogoIcon from "../images/svg/Group 1356.svg";
import FrameLinkIcon from "../images/svg/Frame (2).svg"
import StatusTaskIcon from "../images/svg/Frame 165.svg"
import CompletedIcon from "../images/svg/completed green.svg"
import ProgressIcon from "../images/svg/in progress gold.svg"
import SortAscending from "../images/svg/sort_asc.svg"
import SortDescending from "../images/svg/sort_desc.svg"


export {
    HourGlassIcon,
    TodoListIcon,
    GoldClockIcon,
    StatusIcon,
    SyncDataIcon,
    GroupExitIcon,
    AddSvgIcon,
    SortIcon,
    ArrowRightIcon,
    ArrowLeftIcon,
    LoveIcon,
    CoffeeIcon,
    SearchIcon,
    ChevronIcon,
    HoursRemainingBackground,
    TodoListWhiteIcon,
    CheckListIcon,
    PausedIcon,
    UpToDateIcon,
    RectangleIcon,
    ArcticGreySmallIcon,
    SandwichIcon,
    SearchBoxIcon,
    ArcticGreyLogoIcon,
    Frame5Icon,
    Frame6Icon,
    PlusCircleIcon,
    Frame1Icon,
    Frame4Icon,
    ChevronRightIcon,
    ChevronDownIcon,
    ArcticLogoIcon,
    FrameLinkIcon,
    StatusTaskIcon,
    CompletedIcon,
    ProgressIcon,
    SortAscending,
    SortDescending
}
