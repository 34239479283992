import { createSlice } from '@reduxjs/toolkit'

export const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        editUserModal: false
    },
    reducers: {
        setAdminEditUserModal: (state, action) => {
            state.editUserModal = action.payload
        },
    },
})

export const { setAdminEditUserModal } = adminSlice.actions
const adminReducer = adminSlice.reducer

export default adminReducer
