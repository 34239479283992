import { createSlice } from '@reduxjs/toolkit'

export const projectSlice = createSlice({
    name: 'project',
    initialState: {
        addProjectModal: false,
        editProjectModal: false
    },
    reducers: {
        setProjectAddProjectModal: (state, action) => {
            state.addProjectModal = action.payload
        },
        setEditProjectModal: (state, action) => {
            state.editProjectModal = action.payload
        },
    },
})

export const { setProjectAddProjectModal, setEditProjectModal } = projectSlice.actions
const projectReducer = projectSlice.reducer

export default projectReducer
