import React from "react";
import Frame1 from "../../images/svg/Frame (1).svg";
import {Popover} from '@headlessui/react'

const NotificationComponent = () => {
    return (
        <Popover className="relative">
            <Popover.Button><img src={Frame1}/></Popover.Button>

            <Popover.Panel className="absolute z-10 bg-white shadow-xl rounded-md top-10 right-0 divide-y text-dark">
                {
                    ['Cane Masters', 'Havsies', 'Josh Rosebrook'].map((item, index) => (
                        <div className={"w-full py-3"} key={index}>
                            <div className={"row items-center px-2"}>
                                <p className={"w-32"}>{item}</p>
                                <p className={"w-32 text-red-light font-Poppins-SemiBold"}>00h 00m remaining</p>
                            </div>
                        </div>
                    ))
                }
            </Popover.Panel>
        </Popover>
    )
}

export default NotificationComponent
