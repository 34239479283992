import React from "react";
import HeaderComponent from "./header.component";
import Header from "../public-email/header.component";
import SidebarComponent from "./sidebar.component";
import FooterComponent from "../../components/footer.component";
import ModalComponent from "../../components/modal.component";
import {useDispatch, useSelector} from "react-redux";
import {setProjectAddProjectModal} from "../../reducers/projects.reducer";
import AddProjectModalComponent from "./add-project-modal.component";

const Dashboard = (props) => {
    const {content} = props
    const dispatch = useDispatch()
    const isAuthenticated = false
    const {addProjectModal} = useSelector(state => state.projectReducer)

    const onCloseModal = () => {
        dispatch(setProjectAddProjectModal(false))
    }

    if (!!isAuthenticated) {
        return (
            <div className={"row w-screen h-screen font-Poppins-Regular 2xl:text-xs text-10"}>
                <SidebarComponent/>
                <div className={"w-full flex-1 lg:pl-[200px] 2xl:pl-[240px]"}>
                    <HeaderComponent/>
                    <div className={"bg-creme col w-full h-full justify-between overflow-y-scroll md:p-7 p-5 pb-14"}>
                        {content}
                        <FooterComponent/>
                    </div>
                </div>
                <ModalComponent
                    showHeader={true}
                    title={'add new project'}
                    visibility={addProjectModal}
                    onClose={onCloseModal}
                    content={<AddProjectModalComponent />}
                />
            </div>
        )
    } else {
        return (
            <div className={" col w-screen h-screen font-Poppins-Regular text-xs bg-creme"}>
                <Header/>
                <div
                    className={"w-full h-full mt-[60px] 2k:mt-[90px] 4k:mt-[150px] overflow-y-scroll bg-bg-main bg-contain bg-bottom bg-no-repeat"}>
                    {content}
                </div>
            </div>
        )
    }
}

export default Dashboard
