import {createSlice} from '@reduxjs/toolkit'

export const emailSlice = createSlice({
    name: 'email',
    initialState: {
        totalData: {
            bulkHoursRemaining: 0,
            hoursLogged: 59,
            totalCompleted: 22,
            totalInProgress: 5
        },
        activeButton: 'week',
        taskList: [],
        loading: false,
        projectTitle: ''
    },
    reducers: {
        setEmailTotalData: (state, action) => {
            state.totalData = {...state.totalData, ...action.payload}
        },
        setEmailTaskList: (state, action) => {
            state.taskList = action.payload
        },
        setEmailLoading: (state, action) => {
            state.loading = action.payload
        },
        setEmailProjectTitle: (state, action) => {
            state.projectTitle = action.payload
        },
        setEmailActiveButton: (state, action) => {
            state.activeButton = action.payload
        },
    },
})

export const {
    setEmailTotalData,
    setEmailTaskList,
    setEmailLoading,
    setEmailProjectTitle,
    setEmailActiveButton
} = emailSlice.actions
const emailReducer = emailSlice.reducer

export default emailReducer
