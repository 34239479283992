import React from "react";
import TableComponent from "../../components/table.component";
import CompletedSvg from "../../images/svg/completed green.svg"
import ProgressSvg from "../../images/svg/in progress gold.svg"
import PausedSvg from "../../images/svg/Frame 166.svg"
import {convertSecondToHours} from "../../utils/general-variable";
import {useSelector} from "react-redux";
import TaskListMobileComponent from "./task-list-mobile.component";

const TaskListComponent = () => {
    const {totalData, taskList} = useSelector(state => state.emailReducer)
    const {bulkHoursRemaining} = totalData
    const columns = [
        {
            tableHead: 'Task Name',
            dataIndex: 'name',
            sorting: true
        }, {
            tableHead: 'Status',
            dataIndex: 'status',
            sorting: true,
            render: (status, record) => <img className={"h-3 2k:h-8 4k:h-14 md:h-auto"}
                                             src={record?.status === 'completed' ? CompletedSvg : bulkHoursRemaining === 0 ? PausedSvg : ProgressSvg}/>
        }, {
            tableHead: 'Task Duration',
            dataIndex: 'taskDuration',
            sorting: true,
            render: (taskDuration) => <p>{convertSecondToHours(taskDuration, true)}</p>
        }, {
            tableHead: 'Completion Date',
            dataIndex: 'completionDate',
            sorting: true,
            render: (completion_date) => <p>{!!completion_date && completion_date}</p>
        }
    ]

    return (
        <div>
            <div className={"hidden md:block"}>
                <TableComponent
                    columns={columns}
                    dataSource={taskList}
                    pagination={true}
                />
            </div>
            <div className={"block md:hidden"}>
                <TaskListMobileComponent/>
            </div>
            {
                taskList.length === 0 ? <div className={"w-full justify-center row text-base 2k:text-2xl 4k:text-4xl"}>No match/record found</div> : null
            }
        </div>
    )
}

export default TaskListComponent
